<template>
<v-app> 
   <OptionCRUD column_name="invoice_type" title="發票類型" />
</v-app>
</template>
 

<script>
import OptionCRUD from '@/components/common/OptionCRUD'

export default {
    name: 'manageList',
    components:{OptionCRUD},
    props:[],
    data () {
    return {}
        
    },
    
    methods:{
       
   }
}
</script>
 