<template>
<v-app> 
<v-main>
    <v-app-bar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>管理者</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn icon @click="sheetAdd=true">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
    </v-app-bar> 
     <v-progress-linear v-if="is_loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-sheet class="overflow-y-auto" max-height="600">
       
        <v-list flat>
            <v-list-item-group v-model="model"
                color="primary"
            >
                <div v-for="(item, idx) in search_result"
                :key="idx">
                    <div v-if="item.type=='divider'">                
                        <v-divider v-if="item.divider"></v-divider>
                        <v-subheader>{{item.text}}</v-subheader>
                    </div>
                    <v-list-item v-else :value="idx">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.account" class="accent--text"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row>
                                    <v-col>
                                        <v-btn icon color="grey" @click="onDeleteClick(item)"> 
                                            <v-icon>mdi-delete-outline</v-icon> 
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                            
                        </template>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-sheet>

    <v-bottom-sheet v-model="sheetAdd" persistent>
        <v-card class="text-center" flat>
            <v-card-title>
                <span>新增 管理者</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheetAdd = !sheetAdd"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form1">
                <v-container class="px-6">
                    <v-row>
                        <v-col class="pr-8 pb-0">
                            <v-text-field
                                v-model="add_item.account"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                                label="輸入員工 Email (已註冊且通過驗證者)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" width="150"
                        @click="onAddSave"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
    
</v-main>
</v-app>
</template>
 

<script>
export default {
    name: 'manageList',
    components:{},
    props:[],
    data () {
    return {
            model:"",
            is_loading:false,
            sheetAdd:false,
            items: [],
            search_result:[],
            add_item:{
                account:""
            },
        
        }
    },
    computed:{
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.is_loading=true
            this.$store.dispatch("http/get",{
                api:"users/admins"
            }).then((data)=>{
                setTimeout(()=>{this.is_loading=false},1500)
                
                if(data.status){
                    this.items = data.result
                    this.search_result = data.result
                }else{
                    this.$store.commit('snackBar',{show:true,message:"無法取得資料！",icon:"error",color:""});

                }
            })
        },
       
        onEditClick (item) {
            this.edit_item = item
            this.sheetEdit = true
        },
        onDeleteClick (item) {
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'你確定要刪除'+item.account+'的管理權限?',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.onDeleteSave(item.account)
                }
            }});
        },
        onAddSave(){
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.$store.dispatch("http/post",{
                api:"users/admins",
                json:this.add_item
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear()
                    this.getData()
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
       
        onDeleteSave(id){
            this.$store.dispatch("http/delete",{
                api:`users/admins/${id}`
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"已刪除！",icon:"success",color:""});
                    this.getData()
                }else if (data.code==8){
                    this.$store.commit('snackBar',{show:true,message:"必須留下至少一位管理者！",icon:"error",color:""});
                }else{
                    this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});
                }
            })
        },
        onClear(){
            this.$refs.form1.reset()
        }
   }
}
</script>
 