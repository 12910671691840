<template>
<v-app> 
<v-main>
    <v-toolbar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>選項管理</v-toolbar-title>
    </v-toolbar> 
    <v-list flat>
      <v-list-item-group
         color="primary"
      >
        <div v-for="(item, i) in items"
          :key="i">
            <div v-if="item.type=='divider'">                
                <v-divider v-if="item.divider"></v-divider>
                <v-subheader>{{item.text}}</v-subheader>
            </div>
            <v-list-item v-else  @click="$router.push({path:item.url})">
                <v-list-item-content>
                    <v-list-item-title v-text="item.text" class="accent--text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>

            </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
</v-main>
</v-app>
</template>
 

<script>

export default {
  name: 'manageList',
  components:{},
  props:[],
  data () {
    return {
        items: [
        {
          type: 'divider',
          text: '一般UC',
          divider:false
        },
        {
          url: '/manage/shop_category',
          text: '店型/產品/品牌',
        },
        {
          url: '/manage/mall',
          text: '商圈',
        },
        {
          url: '/manage/sale_predict',
          text: '銷售額預估',
        },
        {
          url: '/manage/channel',
          text: '店型channel',
        },
        {
          url: '/manage/category_type',
          text: '店型產業類別',
        },
        {
          url: '/manage/ec',
          text: '網路銷售平台選項',
        },
         {
          url: '/manage/scale',
          text: '店家規模條件選項',
        },
        {
          url: '/manage/inventorySource',
          text: '庫存來源',
        },
        {
          url: '/manage/openingPeriod',
          text: '開業年期',
        },
        {
          url: '/manage/invoiceType',
          text: '發票類型',
        },
        {
          url: '/manage/PG_price',
          text: '需填寫價格的PG清單',
        },
        {
          url: '/manage/monthlySaleCount',
          text: '輪胎月販售條數',
        },
        {
          url: '/manage/monthlyServedCars',
          text: '每月入廠服務車數',
        },
        {
          url: '/manage/carMonthlySaleForecast',
          text: '汽車保修-估算銷售預測',
        },
        {
          type: 'divider',
          text: '百貨UC',
          divider:true
        },
        {
          url: '/manage/shoppingmall',
          text: '百貨商店/分館',
        },
        {
          url: '/manage/desk',
          text: '櫃位',
        },
        
         
      ],
      model: 1,
      
    }
  },
  computed:{
    },
  created(){
      
  },
   methods:{
      test(d){
          console.log(d)

      }
   }
}
</script>
 