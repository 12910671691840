<template>
<v-app> 
<v-main>
    <v-app-bar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>百貨商店/分館</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn icon @click="sheet.shoppingmall.add=true">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
    </v-app-bar> 
    <v-sheet class="overflow-y-auto" max-height="600">
        <v-container>
            <v-row>
                <v-col cols="10" class="pb-0">
                    <v-select dense outlined clearable
                        v-model="selected.shoppingmall_item"
                        :items="options.shoppingmalls"
                        item-text="name"
                        item-value="shoppingmall_id"
                        label="請選擇百貨商店"
                        return-object
                        :rules="$store.getters['validation/inputRules'](['required'])"
                    ></v-select>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                    <v-btn icon class="pa-0" v-if="selected.shoppingmall_item"
                        @click="sheet.shoppingmall.edit=true" 
                        color="primary">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
           
            <div v-if="selected.shoppingmall_item" class="grey--text mb-2">
                <v-row>
                    <v-col class="pt-0">
                        <v-btn text class="pl-0"
                            @click="sheet.department.add=true" 
                            color="accent" 
                            width="120">
                            <v-icon>mdi-plus</v-icon>
                            增加分館  
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
           
            <v-row> 
                <v-progress-linear v-show="is_loading" class="mt-4"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-col class="px-0 pt-0">
                    <v-expand-transition>
                        <v-row>
                            <v-col class="text-center">
                                <v-sheet tile :height="w_heigth" class="overflow-y-auto" v-if="data_list.departments.length>0">
                                    <v-list-item-group mandatory
                                        v-model="selected.department"
                                    >
                                        <template v-for="(department, i) in data_list.departments">
                                            <!-- <v-divider :key="`divider1-${i}`"></v-divider> -->
                                            <v-list-item 
                                                :key="`item-p-${i}`"
                                                :value="department"
                                                active-class="yellow lighten-5 third--text"
                                            >
                                                <template v-slot:default="{    }">
                                                
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col class="pa-0 pl-2">
                                                            <v-btn icon small @click="onDeleteClickDepartment(department)" color="primary">
                                                                <v-icon>mdi-delete-outline</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col class="pa-0 pt-2">
                                                            <v-list-item-title >{{department.department_name}}</v-list-item-title>
                                                        </v-col>
                                                        <v-col class="pa-0">
                                                            <v-btn icon small @click="onEditClickDepartment(department)" color="primary">
                                                                <v-icon>mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-sheet>
                            </v-col>
                            
                        </v-row>
                    </v-expand-transition>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
    <v-bottom-sheet v-model="sheet.shoppingmall.add" persistent>
        <v-card class="text-center" flat tile>
            <v-card-title>
                <span>新增 百貨商店</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheet.shoppingmall.add = false"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form_add_shoppingmall">
                <v-container class="px-6">
                    <v-row>
                        <v-col>
                            <v-text-field clearable
                                v-model="add_item.shoppingmall.name"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                                label="百貨商店名稱"
                            ></v-text-field>
                            
                        </v-col>
                    </v-row>
                    <v-btn color="primary" width="150"
                        @click="onAddSaveShoppingmall()" :loading="is_loading"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
    <div v-if="selected.shoppingmall_item">
        <v-bottom-sheet v-model="sheet.shoppingmall.edit" persistent v-if="edit_item.shoppingmall">
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>編輯 百貨商店</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.shoppingmall.edit = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_edit_shoppingmall">
                    <v-container class="px-6">
                        <v-row>
                            <v-col>
                                <v-text-field clearable
                                    v-model="edit_item.shoppingmall.name"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                    label="店型中文名稱"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" width="150"
                            @click="onEditSaveShoppingmall()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="sheet.department.add" persistent>
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>新增 分館</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.department.add = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_add_department">
                    {{selected.shoppingmall_item.name}}
                    <v-container>
                        <v-text-field clearable
                            v-model="add_item.department.department_name"
                            :rules="$store.getters['validation/inputRules'](['required'])"
                            label="分館名稱"
                        ></v-text-field>
                        <v-btn color="primary" width="150"
                            @click="onAddSaveDepartment()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="sheet.department.edit" persistent v-if="edit_item.department">
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>編輯 分館</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.department.edit = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_edit_department">
                    <v-container class="px-6">
                        <v-row>
                            <v-col>
                                <v-text-field clearable
                                    v-model="edit_item.department.department_name"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                    label="分館名稱"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" width="150"
                            @click="onEditSaveDepartment()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
    </div>
</v-main>
</v-app>
</template>

<style>

/* .keyboard-on > .v-autocomplete__content{
    padding-top:45px !important;
} */

</style>
<script>
export default {
    props:[],
    data () {
    return {
        is_loading:false,
        sheet:{
            shoppingmall:{
                add:false,
                edit:false
            },
            department:{
                add:false,
                edit:false
            },
            brand:{
                add:false,
                edit:false
            },
        },
        options:{
            shoppingmalls:[],
            departments:[]
        },
        selected:{
            shoppingmall_item:"",
            department:"",
        },
        data_list:{
            departments:[],
            // brands:[]
        },
        search: null,
        add_item:{
            shoppingmall:{
                name:"",
            },
            department:{
                department_name:""
            }
        },
        edit_item:{
            shoppingmall:null,
            department:null,
        },
        }
    },
    computed:{
        w_heigth(){
            return (window.innerHeight*0.9)-150;
        },
        sc_heigth(){
            return screen.height;
        },
        
    },
    mounted(){
        this.getOptions('shoppingmalls')
        // this.getOptions('departments')
       
    },
    watch:{
        "options.shoppingmalls":{
            handler(newVal,oldVal){
                if(this.selected.shoppingmall_item&&newVal){
                    this.selected.shoppingmall_item = newVal.find(x=>x.shoppingmall_id==this.edit_item.shoppingmall.shoppingmall_id)

                }
            }
        },
       
        "selected.department":{
            handler(newVal,oldVal){
                // if(newVal&&newVal.length>0){
                //     this.selected.brands = newVal.brands

                // }else{
                //     this.selected.brands = []
                // }
                console.log("changed",this.selected.brands)
                this.selected.brands = []
            }
        },
        "selected.shoppingmall_item":{
            handler(newVal,oldVal){
                if(newVal){
                    this.getOptions('departments',newVal.shoppingmall_id)
                }else{
                    this.cleanData()
                }
                
            },
            deep:true,
            immediate:true

        },
        "data_list.departments":{
            handler(newVal,oldVal){
                if(newVal&&newVal.length>0){
                    if(this.selected.department){
                        console.log("data_list.departments true")
                        let hasData = newVal.find(x=>x.department_id==this.selected.department.department_id)
                        if(hasData){
                            this.selected.department = hasData
                        }else{
                            this.selected.department=""
                        }
                    }
                }else{
                    console.log("data_list.departments false")
                    this.selected.department=[]
                }
            },
            deep:true
        },
        "sheet.shoppingmall.edit":{
            handler(newVal,oldVal){
                if(newVal){
                    this.edit_item.shoppingmall = {
                        shoppingmall_id:this.selected.shoppingmall_item.shoppingmall_id,
                        name:this.selected.shoppingmall_item.name
                    }

                }
            }
        },
    },
    methods:{
       
        filter (item, queryText, itemText) {

            if (item.header) return false
            this.is_loading=true
            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            let result = text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
            this.is_loading=false
            return result
        },
        getOptions(action,params=""){
            this.is_loading=true
            if(action=='shoppingmalls'){
                this.$store.dispatch("http/get",{
                    api:`shoppingmalls`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        this.options.shoppingmalls = data.result

                    }
                })
            }else if(action=='departments'){
                this.$store.dispatch("http/get",{
                    api:`shoppingmalls/${params}/departments`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        this.data_list.departments = data.result

                    }
                })
            }else{
                this.is_loading=false
            }
        },
        onAddSaveShoppingmall(){
            if (!this.$refs.form_add_shoppingmall.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/post",{
                api:"shoppingmalls",
                json:this.add_item.shoppingmall
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear("form_add_shoppingmall")
                    this.getOptions('shoppingmalls')
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onEditSaveShoppingmall(){
            if (!this.$refs.form_edit_shoppingmall.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/put",{
                api:`shoppingmalls/${this.edit_item.shoppingmall.shoppingmall_id}`,
                json:this.edit_item.shoppingmall
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear("form_edit_shoppingmall")
                    this.getOptions('shoppingmalls')
                    this.sheet.shoppingmall.edit=false
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        
        onAddSaveDepartment(){
            if (!this.$refs.form_add_department.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/post",{
                api:"shoppingmalls/department",
                json:{
                    shoppingmall_id:this.selected.shoppingmall_item.shoppingmall_id,
                    department_name:this.add_item.department.department_name
                }
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"儲存成功！",icon:"success",color:""});
                    this.getOptions('departments',this.selected.shoppingmall_item.shoppingmall_id)
                    this.onClear("form_add_department")

                }else{
                    this.is_loading=false
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
                
            })
        },
        onEditClickDepartment(item){
            this.sheet.department.edit=true
            this.edit_item.department = {
                department_id:item.department_id,
                shoppingmall_id:item.shoppingmall_id,
                department_name:item.department_name
            }
        },
         onEditSaveDepartment(){
            if (!this.$refs.form_edit_department.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/put",{
                api:`shoppingmalls/department/${this.edit_item.department.department_id}`,
                json:this.edit_item.department
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear("form_edit_department")
                    this.getOptions('departments',this.edit_item.department.shoppingmall_id)
                    this.sheet.department.edit=false
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onDeleteClickDepartment(item){            
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:`你確定要刪除分館 ${item.department_name}?`,
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.onDeleteSaveDepartment(item.department_id)
                }
            }});
        },
       
        onDeleteSaveDepartment(department_id){
            this.$store.dispatch("http/delete",{
                api:`shoppingmalls/department/${department_id}`
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"刪除成功！",icon:"success",color:""});
                    this.getOptions('departments',this.selected.shoppingmall_item.shoppingmall_id)

                }else{
                    this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});

                }
            })
        },
        cleanData(){
            this.data_list.departments=[]
        },
        onClear(form){
            this.$refs[form].reset()
        }
   }
}
</script>
 