<template>
<v-app v-bind:class="{'keyboard-on':is_keyboard_open}"> 
<v-main>
    <v-app-bar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>店型/產品/品牌</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn icon @click="sheet.category.add=true">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
    </v-app-bar> 
    <v-sheet class="overflow-y-auto" max-height="600">
        <v-container>
            <v-row>
                <v-col cols="10" class="pb-0">
                    <v-select dense outlined clearable
                        v-model="selected.shop_category_item"
                        :items="$store.state.options.shop_categories"
                        item-text="name"
                        label="請選擇商店店型"
                        return-object
                        :rules="$store.getters['validation/inputRules'](['required'])"
                    ></v-select>
                </v-col>
                <v-col cols="2" class="px-0 pb-0">
                    <v-btn icon class="pa-0" v-if="selected.shop_category_item"
                        @click="sheet.category.edit=true" 
                        color="primary">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
           
            <div v-if="selected.shop_category_item" class="grey--text mb-2">
                <v-row>
                    <v-col class="py-0">
                        產業類別：{{selected.shop_category_item.type_name}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0 pr-0">
                        Channel：{{selected.shop_category_item.channel_name}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn text class="pl-0"
                            @click="sheet.product.add=true" 
                            color="accent" 
                            width="120">
                            <v-icon>mdi-plus</v-icon>
                            增加產品  
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
           
            <v-row> 
                <v-progress-linear v-show="is_loading" class="mt-4"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-col class="px-0 pt-0">
                    <v-card tile v-if="selected.brands&&selected.brands.length>0">
                        <v-card-actions>
                            已選擇 {{selected.brands.length}} 項
                            <v-spacer></v-spacer>
                            <v-btn text color="accent" @click="onDeleteClickBrand">全部刪除</v-btn>
                            <v-btn text color="secondary" @click="selected.brands=[]">取消</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-expand-transition>
                        <v-row>
                            <v-col cols="5" class="pr-0 text-center">
                                <v-sheet tile :height="w_heigth" class="overflow-y-auto" v-if="data_list.products.length>0">
                                    <v-list-item-group mandatory
                                        v-model="selected.product"
                                    >
                                        <template v-for="(product, i) in data_list.products">
                                            <!-- <v-divider :key="`divider1-${i}`"></v-divider> -->
                                            <v-list-item 
                                                :key="`item-p-${i}`"
                                                :value="product"
                                                active-class="yellow lighten-5 third--text"
                                            >
                                                <template v-slot:default="{    }">
                                                
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col class="pa-0 pl-2">
                                                            <v-btn icon small @click="onDeleteClickProduct(product.product_id)" color="primary">
                                                                <v-icon>mdi-minus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col class="pa-0 pt-2">
                                                            <v-list-item-title >{{product.product_id}}</v-list-item-title>
                                                        </v-col>
                                                        <v-col class="pa-0">
                                                            <v-btn icon small @click="sheet.brand.add=true" color="primary">
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-sheet>
                            </v-col>
                            <v-col cols="7" class="px-0">
                                <v-sheet tile :height="w_heigth" class="overflow-y-auto"  >
                                    <v-list-item-group style="border-left: solid; border-left-width: 1px;border-left-color: #cac8c8;"
                                        v-model="selected.brands" 
                                        multiple
                                    >
                                    <template v-for="(brand, i) in selected.product.brands">
                                        <!-- <v-divider :key="`divider2-${i}`"></v-divider> -->
                                        <v-list-item 
                                            :key="`item-b-${i}`"
                                            :value="brand"
                                            active-class="yellow lighten-5 third--text"
                                        >
                                            <template v-slot:default="{ active }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="brand.brand_name"></v-list-item-title>
                                                </v-list-item-content>
                                            
                                                <v-list-item-action>
                                                    <v-checkbox
                                                    :input-value="active"
                                                    color="secondary"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </template>
                                    </v-list-item-group>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
    <v-bottom-sheet v-model="sheet.category.add" persistent>
        <v-card class="text-center" flat tile>
            <v-card-title>
                <span>新增 店型</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheet.category.add = false"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form_add_category">
                <v-container class="px-6">
                    <v-row>
                        <v-col>
                            <v-text-field clearable
                                v-model="add_item.category.name"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                                label="店型中文名稱"
                            ></v-text-field>
                            <v-text-field clearable
                                v-model="add_item.category.name_en"
                                label="英文名稱"
                            ></v-text-field>
                            <v-select dense outlined clearable
                                v-model="add_item.category.channel_id"
                                :items="options.channels"
                                item-text="name"
                                item-value="channel_id"
                                label="店型channel"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                            ></v-select>
                            <v-select dense outlined clearable
                                v-model="add_item.category.type_id"
                                :items="options.types"
                                item-text="name"
                                item-value="type_id"
                                label="店型產業類別"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" width="150"
                        @click="onAddSaveCategory()" :loading="is_loading"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
    <div v-if="selected.shop_category_item">
        <v-bottom-sheet v-model="sheet.category.edit" persistent v-if="edit_item.category">
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>編輯 店型</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.category.edit = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_edit_category">
                    <v-container class="px-6">
                        <v-row>
                            <v-col>
                                <v-text-field clearable
                                    v-model="edit_item.category.name"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                    label="店型中文名稱"
                                ></v-text-field>
                                <v-text-field clearable
                                    v-model="edit_item.category.name_en"
                                    label="英文名稱"
                                ></v-text-field>
                                <v-select dense outlined clearable
                                    v-model="edit_item.category.channel_id"
                                    :items="options.channels"
                                    item-text="name"
                                    item-value="channel_id"
                                    label="店型channel"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                ></v-select>
                                <v-select dense outlined clearable
                                    v-model="edit_item.category.type_id"
                                    :items="options.types"
                                    item-text="name"
                                    item-value="type_id"
                                    label="店型產業類別"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" width="150"
                            @click="onEditSaveCategory()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="sheet.product.add" persistent fullscreen>
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>新增 產品</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.product.add = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_add_product">
                    {{selected.shop_category_item.name}}
                    <v-container>
                        <v-combobox ref="combobox_product"  
                            solo clearable multiple small-chips hide-selected
                            v-model="add_item.products"
                            item-text="product_id"
                            :filter="filter"
                            :hide-no-data="!search"
                            :items="options.products"
                            :search-input.sync="search"
                            label="搜尋產品或新增(英文代號)"
                            :rules="$store.getters['validation/inputRules'](['required'])"

                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                <span class="subheading">新增產品 </span>
                                <v-chip
                                    :color="`info`"
                                    label
                                    small
                                >
                                    {{ search }}
                                </v-chip>
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :color="`${item.color}`"
                                :input-value="selected"
                                label
                                small
                                >
                                <span class="pr-2">
                                    {{ item.product_id }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    mdi-close
                                </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                v-if="editing === item"
                                v-model="editing.text"
                                autofocus
                                flat
                                background-color="transparent"
                                hide-details
                                solo
                                @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <v-chip
                                v-else
                                :color="`${item.color}`"
                                dark
                                label
                                small
                                >
                                {{ item.product_id }}
                                </v-chip>
                                
                            </template>
                        </v-combobox>
                        <v-btn color="primary" width="150"
                            @click="onAddSaveProduct()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="sheet.brand.add" persistent fullscreen>
            <v-card class="text-center" flat tile>
                <v-card-title>
                    <span>新增 品牌</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="sheet.brand.add = false"
                    > <v-icon>mdi-close</v-icon> </v-btn>
                </v-card-title>
                <v-form ref="form_add_brand">
                    {{selected.shop_category_item.name}} / {{selected.product.product_id}}
                    <v-container>
                        <v-combobox ref="combobox_product"  
                            solo clearable multiple small-chips hide-selected
                            v-model="add_item.brands"
                            item-text="brand_name"
                            :filter="filter"
                            :hide-no-data="!search"
                            :items="options.brands"
                            :search-input.sync="search"
                            label="搜尋品牌或新增"
                            :rules="$store.getters['validation/inputRules'](['required'])"

                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                <span class="subheading">新增品牌 </span>
                                <v-chip
                                    color="info"
                                    label
                                    small
                                >
                                    {{ search }}
                                </v-chip>
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip
                                v-if="item === Object(item)"
                                v-bind="attrs"
                                :color="`${item.color}`"
                                :input-value="selected"
                                label
                                small
                                >
                                <span class="pr-2">
                                    {{ item.brand_name }}
                                </span>
                                <v-icon
                                    small
                                    @click="parent.selectItem(item)"
                                >
                                    mdi-close
                                </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                v-if="editing === item"
                                v-model="editing.text"
                                autofocus
                                flat
                                background-color="transparent"
                                hide-details
                                solo
                                @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <v-chip
                                v-else
                                :color="`${item.color}`"
                                dark
                                label
                                small
                                >
                                {{ item.brand_name }}
                                </v-chip>
                                
                            </template>
                        </v-combobox>
                        <v-btn color="primary" width="150"
                            @click="onAddSaveBrand()" :loading="is_loading"
                        > 儲存</v-btn>
                    </v-container>
                </v-form>
        </v-card>
        </v-bottom-sheet>
    </div>
</v-main>
</v-app>
</template>

<style>

/* .keyboard-on > .v-autocomplete__content{
    padding-top:45px !important;
} */

</style>
<script>
export default {
    props:[],
    data () {
    return {
        is_loading:false,
        sheet:{
            category:{
                add:false,
                edit:false
            },
            product:{
                add:false,
                edit:false
            },
            brand:{
                add:false,
                edit:false
            },
        },
        options:{
            channels:[],
            types:[],
            products:[],
            brands:[]
        },
        selected:{
            shop_category_item:"",
            product:"",
            brands:""
        },
        data_list:{
            products:[],
            // brands:[]
        },
        editing: null,
        editingIndex: -1,
        search: null,
     
        add_item:{
            category:{
                name:"",
                name_en:"",
                type_id:"",
                channel_id:""
            },
            products:[
                
            ],
            brands:[
                
            ]
        },
        edit_item:{
            category:null,
            product:null,
            brand:null
        },
        visualViewport:"",
        is_keyboard_open:false
        }
    },
    computed:{
        w_heigth(){
            return(window.innerHeight*0.9)-150;
        },
        sc_heigth(){
            return screen.height;
        },
        
    },
    mounted(){
        this.$store.dispatch("options/getShopCategories")
        this.getOptions('channels')
        this.getOptions('categoryTypes')
        this.getOptions('products')
        this.getOptions('brands')
        this.visualViewport=screen.height
        window.visualViewport.onresize = (e) => {
            this.visualViewport=e.currentTarget.height
        }
    },
    watch:{
        "visualViewport":{
            handler(newVal,oldVal){
                if(this.selected.shop_category_item){
                    if(this.$refs.combobox_product.isFocused&&oldVal-newVal>=200){
                        this.is_keyboard_open = true
                    }else{
                        this.is_keyboard_open = false
                    }
                }
                
            }
        },
        "selected.product":{
            handler(newVal,oldVal){
                // if(newVal&&newVal.length>0){
                //     this.selected.brands = newVal.brands

                // }else{
                //     this.selected.brands = []
                // }
                this.selected.brands = []
            }
        },
        "selected.shop_category_item":{
            handler(newVal,oldVal){
                if(newVal){
                    this.getOptions('categories/products',newVal.shop_category_id)
                }else{
                    this.cleanData()
                }
                
            },
            deep:true,
            immediate:true

        },
        "data_list.products":{
            handler(newVal,oldVal){
                if(newVal&&newVal.length>0){
                    if(this.selected.product){
                        let hasData = newVal.find(x=>x.product_id==this.selected.product.product_id)
                        if(hasData){
                            this.selected.product = hasData
                        }else{
                            this.selected.product=""
                        }
                    }
                }else{
                    this.selected.product=[]
                }
            },
            deep:true
        },
        "sheet.category.edit":{
            handler(newVal,oldVal){
                if(newVal){
                    this.edit_item.category = {
                        shop_category_id:this.selected.shop_category_item.shop_category_id,
                        name:this.selected.shop_category_item.name,
                        name_en:this.selected.shop_category_item.name_en,
                        type_id:this.selected.shop_category_item.type_id,
                        channel_id:this.selected.shop_category_item.channel_id
                    }

                }
            }
        },
        "add_item.products" (val, prev) {
            if (val.length === prev.length) return

            this.add_item.products = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        product_id: v,
                        color: 'info',
                    }
                    this.options.products.push(v)
                }
                return v
            })
        },
        "add_item.brands" (val, prev) {
            if (val.length === prev.length) return

            this.add_item.brands = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        brand_id: "",
                        brand_name:v,
                        color: 'info',
                    }
                    this.options.brands.push(v)
                }
                return v
            })
        },
    },
    methods:{
       
        edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
        filter (item, queryText, itemText) {

            if (item.header) return false
            this.is_loading=true
            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            let result = text.toString()
            .toLowerCase()
            .indexOf(query.toString().toLowerCase()) > -1
            this.is_loading=false
            return result
        },
        getOptions(action,params=""){
            this.is_loading=true
            if(action=='categoryTypes'){
                this.$store.dispatch("http/get",{
                    api:`categoryTypes`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        this.options.types = data.result

                    }
                })
            }else if(action=='channels'){
                this.$store.dispatch("http/get",{
                    api:`channels`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        this.options.channels = data.result

                    }
                })
            }else if(action=='products'){
                this.$store.dispatch("http/get",{
                    api:`products`,
                }).then((data)=>{
                    if(data.status){
                        this.is_loading=false
                        data.result = data.result.map((item)=>{
                            item.color='primary'
                            return item
                        })
                        this.options.products = data.result

                    }
                })
            }else if(action=='categories/products'){
                this.$store.dispatch("http/get",{
                    api:`categories/${params}/products/brands`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        this.data_list.products=data.result

                    }
                })
            }else if(action=='brands'){
                this.$store.dispatch("http/get",{
                    api:`brands`,
                }).then((data)=>{
                    this.is_loading=false
                    if(data.status){
                        data.result = data.result.map((item)=>{
                            item.color='primary'
                            return item
                        })
                        this.options.brands = data.result
                    }
                })
            }else{
                this.is_loading=false
            }
        },
        onDeleteClickProduct(product_id){
            let product = this.data_list.products.find(x=>x.product_id==product_id)
            if(product&&product.brands.length>0){
                this.$store.commit('dialogBox',{dialog:true,option:{
                    btnConfirmText:'我知道了',
                    message:`此產品 ${product_id} 底下還有未刪除的品牌，請先刪除所有品牌！`,
                    btnCancelShow:false,
                    icon:'warning',
                }});
            }else{
                this.onDeleteSaveProduct(product_id)
            }
           
        },
        onDeleteClickBrand () {
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:`你確定要刪除產品 ${this.selected.product.product_id} 中所勾選的 ${this.selected.brands.length}項 品牌?`,
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.onDeleteSaveBrand()
                }
            }});
        },
        onDeleteSaveProduct(product_id){
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:`你確定要刪除產品 ${product_id}?`,
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.$store.dispatch("http/delete",{
                        api:`products/${product_id}`
                    }).then((data)=>{
                        if(data.status){
                            this.$store.commit('snackBar',{show:true,message:"刪除成功！",icon:"success",color:""});
                            this.getOptions('categories/products',this.selected.shop_category_item.shop_category_id)

                        }else{
                            this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});
                        }
                    });
                }
            }});
             
        },
        onDeleteSaveBrand(){
            this.is_loading=true
            this.$store.dispatch("http/post",{
                api:"products/delete/brands",
                json:this.selected.brands
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"刪除成功！",icon:"success",color:""});
                    this.getOptions('categories/products',this.selected.shop_category_item.shop_category_id)
                    this.selected.brands = []
                }else{
                    this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});

                }
            })
        },
        onAddSaveCategory(){
            if (!this.$refs.form_add_category.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/post",{
                api:"categories",
                json:this.add_item.category
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear("form_add_category")
                    this.$store.dispatch("options/getShopCategories")
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onEditSaveCategory(){
            if (!this.$refs.form_edit_category.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/put",{
                api:`categories/${this.edit_item.category.shop_category_id}`,
                json:this.edit_item.category
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear("form_edit_category")
                    this.$store.dispatch("options/getShopCategories")
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onAddSaveProduct(){
            if (!this.$refs.form_add_product.validate()) {
                return false
            }
            this.is_loading=true
            this.$store.dispatch("http/post",{
                api:"products",
                json:this.add_item.products
            }).then((data)=>{
                if(data.status){
                    let tmp_json = this.add_item.products.map((x)=>{
                        x.shop_category_id = this.selected.shop_category_item.shop_category_id
                        return x
                    })
                    this.$store.dispatch("http/post",{
                        api:"categories/products",
                        json:tmp_json
                    }).then((data)=>{
                        this.is_loading=false
                        if(data.status){
                            this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                            this.getOptions('categories/products',this.selected.shop_category_item.shop_category_id)
                            this.onClear("form_add_product")
                            this.sheet.product.add=false
                            
                        }else{
                            this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                        }
                    })
                }else{
                    this.is_loading=false
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
                
            })
        },
        onAddSaveBrand(){
            if (!this.$refs.form_add_brand.validate()) {
                return false
            }
            this.is_loading=true
            let tmp_json = this.add_item.brands.map((x)=>{
                x.shop_category_id = this.selected.shop_category_item.shop_category_id
                x.product_id = this.selected.product.product_id

                return x
            })
            console.log(tmp_json)
            this.$store.dispatch("http/post",{
                api:"products/brands",
                json:tmp_json
            }).then((data)=>{
                this.is_loading=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.getOptions('categories/products',this.selected.shop_category_item.shop_category_id)
                    this.onClear("form_add_brand")
                    this.sheet.brand.add=false
                    
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
       
        cleanData(){
            this.data_list.products=[]
        },
        onClear(form){
            this.$refs[form].reset()
        }
   }
}
</script>
 