<template>
<v-app> 
<v-main>
    <v-app-bar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>銷售額預估</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn icon @click="sheetAdd=true">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
    </v-app-bar> 
    <v-expansion-panels v-model="panel">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                    <v-row no-gutters>
                        <v-col cols="4">查詢條件</v-col>
                        <v-col cols="8" class="text--secondary">
                            <v-fade-transition leave-absolute>
                                <span v-if="open" key="0">因資料量大，請輸入查詢條件</span>
                                <span v-else key="0">{{query_info}}</span>

                             </v-fade-transition>
                        </v-col>
                    </v-row>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form ref="form_search">
                    <v-container>
                        
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select dense outlined clearable
                                    v-model="query.shop_category_id"
                                    :items="$store.state.options.shop_categories"
                                    item-text="name"
                                    item-value="shop_category_id"
                                    label="商店店型"
                                    :rules="$store.getters['validation/inputRules'](['required'])"
                                ></v-select>
                            </v-col> 
                            <v-col cols="12"  sm="6">
                                <v-select
                                    :items="$store.state.options.cities"
                                    v-model="query.city"
                                    label="縣市"
                                    dense outlined clearable
                                ></v-select>
                            </v-col> 
                        </v-row>
                        <p class="d-flex justify-start subtitle-2 mb-1">規模</p>
                        <v-btn-toggle tile
                            color="secondary" class="d-flex justify-start mb-6"
                            v-model="query.shop_scale" >
                            <v-btn :value="'XS'" >XS</v-btn>
                            <v-btn :value="'S'" >S</v-btn>
                            <v-btn :value="'M'" >M</v-btn>
                            <v-btn :value="'L'" >L</v-btn>
                            <v-btn :value="'XL'" >XL</v-btn>

                        </v-btn-toggle>
                        <v-btn block color="primary"
                        @click="onComplexSearch" :loading="loading.search_btn"
                        > 搜尋
                        </v-btn>
                    </v-container>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-sheet class="overflow-y-auto" max-height="600">
        <v-list flat>
            <v-subheader class="d-flex justify-center">共{{search_result.length}}筆資料</v-subheader>
            <v-list-item-group
                color="primary"
            >
                <div v-for="(item, idx) in search_result"
                :key="idx">
                    <div v-if="item.type=='divider'">                
                        <v-divider v-if="item.divider"></v-divider>
                        <v-subheader>{{item.text}}</v-subheader>
                    </div>
                    <v-list-item v-else :value="idx">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title class="accent--text">{{item.city}} / {{item.shop_scale}}</v-list-item-title>
                                <v-list-item-title class="accent--text">{{item.amount}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row>
                                    <v-col>
                                        <v-btn icon color="grey" @click="onDeleteClick(item)"> 
                                            <v-icon>mdi-delete-outline</v-icon> 
                                        </v-btn>
                                        <v-btn icon color="primary" @click="onEditClick(item)"> 
                                            <v-icon>mdi-pencil</v-icon> 
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                            
                        </template>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-sheet>

    <v-bottom-sheet v-model="sheetAdd" persistent>
        <v-card class="text-center" flat>
            <v-card-title>
                <span>新增 銷售預估</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheetAdd = !sheetAdd"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form1">
                <v-container class="px-6">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select dense outlined clearable
                                v-model="add_item.shop_category_id"
                                :items="$store.state.options.shop_categories"
                                item-text="name"
                                item-value="shop_category_id"
                                label="商店店型"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                            ></v-select>
                        </v-col> 
                        <v-col cols="12"  sm="6">
                            <v-select
                                :items="$store.state.options.cities"
                                v-model="add_item.city"
                                label="縣市"
                                dense outlined clearable
                            ></v-select>
                        </v-col> 
                    </v-row>
                    <p class="d-flex justify-start subtitle-2 mb-1">規模</p>
                    <v-btn-toggle tile
                        color="secondary" class="d-flex justify-start mb-6"
                        v-model="add_item.shop_scale" >
                        <v-btn :value="'XS'" >XS</v-btn>
                        <v-btn :value="'S'" >S</v-btn>
                        <v-btn :value="'M'" >M</v-btn>
                        <v-btn :value="'L'" >L</v-btn>
                        <v-btn :value="'XL'" >XL</v-btn>

                    </v-btn-toggle>
                    <v-text-field
                        v-model="add_item.amount"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                        label="輸入銷售額範圍"
                        placeholder="ex: >100,000 , <200,000"
                    ></v-text-field>
                    <v-btn color="primary" width="150"
                        @click="onAddSave" :loading="loading.save_btn"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="sheetEdit" persistent>
        <v-card class="text-center" flat>
            <v-card-title>
                <span>編輯 銷售預估</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheetEdit = !sheetEdit"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form2">
                <v-container class="px-6">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select dense outlined clearable
                                v-model="edit_item.shop_category_id"
                                :items="$store.state.options.shop_categories"
                                item-text="name"
                                item-value="shop_category_id"
                                label="商店店型"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                            ></v-select>
                        </v-col> 
                        <v-col cols="12"  sm="6">
                            <v-select
                                :items="$store.state.options.cities"
                                v-model="edit_item.city"
                                label="縣市"
                                dense outlined clearable
                            ></v-select>
                        </v-col> 
                    </v-row>
                    <p class="d-flex justify-start subtitle-2 mb-1">規模</p>
                    <v-btn-toggle tile
                        color="secondary" class="d-flex justify-start mb-6"
                        v-model="edit_item.shop_scale" >
                        <v-btn :value="'XS'" >XS</v-btn>
                        <v-btn :value="'S'" >S</v-btn>
                        <v-btn :value="'M'" >M</v-btn>
                        <v-btn :value="'L'" >L</v-btn>
                        <v-btn :value="'XL'" >XL</v-btn>

                    </v-btn-toggle>
                    <v-text-field
                        v-model="edit_item.amount"
                        :rules="$store.getters['validation/inputRules'](['required'])"
                        label="輸入銷售額範圍"
                        placeholder="ex: >100,000 , <200,000"
                    ></v-text-field>
                    <v-btn color="primary" width="150"
                        @click="onEditSave" :loading="loading.save_btn"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
</v-main>
</v-app>
</template>
 

<script>
 export default {
    components:{ },
    props:[],
    data () {
    return {
        panel:[],
        sheetAdd:false,
        sheetEdit:false,
        search_result:[],
        loading:{
            search_btn:false,
            save_btn:false,
        },
        query:{
            shop_category_id:null,
            city:null,
            shop_scale:null
        },
        add_item:{
            shop_category_id:"",
            city:"",
            shop_scale:"",
            amount:""
        },
        edit_item:{}
        }
    },
    computed:{
        query_info(){
            let item = this.$store.state.options.shop_categories.find(x=>x.shop_category_id==this.query.shop_category_id)
            let city = this.query.city?'/'+this.query.city:""
            let shop_scale = this.query.shop_scale?'/'+this.query.shop_scale:""

            return item?`${item.name}${city}${shop_scale}`:""
        }
    },
    mounted(){
        this.$store.dispatch("options/getShopCategories")
    },
   
    methods:{
        checkQuery(obj){
            let params = {}
            for(let key in obj){
                let val = obj[key]
                let valType = typeof(val)
                switch (valType) {
                    case 'string':
                        if(val.length>0) params[key] = val
                        break;
                    case 'object':
                        if(val&&Object.keys(val).length>0) params[key] = val
                        break;
                    case 'undefined':
                        break;
                    default:
                        params[key] = val
                        break;
                }

            }
            return {
                params:params,
                status:Object.keys(params).length==0?false:true
            }
        },
       
        onEditClick (item) {
            this.edit_item = item
            this.sheetEdit = true
        },
        onDeleteClick (item) {
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'你確定要刪除?',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.onDeleteSave(item)
                }
            }});
        },
        onAddSave(){
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.loading.save_btn=true

            this.$store.dispatch("http/post",{
                api:"salePredict",
                json:this.add_item
            }).then((data)=>{
                this.loading.save_btn=false

                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear()
                    this.onComplexSearch()
                }else if(data.code==3){
                    this.$store.commit('snackBar',{show:true,message:"新增失敗，此條件已經存在！",icon:"error",color:""});

                }
            })
        },
        onEditSave(){
            if (!this.$refs.form2.validate()) {
                return false
            }
            this.loading.save_btn=true
            this.$store.dispatch("http/put",{
                api:`salePredict/${this.edit_item.city}/${this.edit_item.shop_category_id}/${this.edit_item.shop_scale}`,
                json:this.edit_item
            }).then((data)=>{
                this.loading.save_btn=false
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"儲存成功！",icon:"success",color:""});
                    this.sheetEdit=false
                    this.onComplexSearch()
                    
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onDeleteSave(item){
            this.$store.dispatch("http/delete",{
                api:`salePredict/${item.city}/${item.shop_category_id}/${item.shop_scale}`,
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"已刪除！",icon:"success",color:""});
                    this.onComplexSearch()
                }else{
                    this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});

                }
            })
        },
        onComplexSearch(){
            let check = this.checkQuery(this.query)
            if(check.status){
                this.$store.dispatch("http/post",{
                    api:`salePredict/search`,
                    json:check.params
                }).then((data)=>{
                    if(data.status){
                        this.search_result = data.result;
                        this.panel=undefined
                    }
                })
            }
        },
        onClear(){
            this.$refs.form1.reset()
        }
   }
}
</script>
 