<template>
<v-app> 
    <OptionCRUD column_name="shop_scale_condition" title="店家規模條件" />

<!-- <v-main>
    <v-app-bar color="accent" class="white--text">
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>店家規模條件</v-toolbar-title>
      <v-spacer></v-spacer>
        <v-btn icon @click="sheetAdd=true">
            <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
    </v-app-bar> 
    <v-sheet class="overflow-y-auto" max-height="600">
      
        <v-list flat>
            <v-list-item-group v-model="model"
                color="primary"
            >
                <div v-for="(item, idx) in items"
                :key="idx">
                   
                    <v-list-item  :value="idx">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name" class="accent--text"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row>
                                    <v-col>
                                        <v-btn icon color="grey" @click="onDeleteClick(item)"> 
                                            <v-icon>mdi-delete-outline</v-icon> 
                                        </v-btn>
                                        <v-btn icon color="primary" @click="onEditClick(item)"> 
                                            <v-icon>mdi-pencil</v-icon> 
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                            
                        </template>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>
    </v-sheet>

    <v-bottom-sheet v-model="sheetAdd" persistent>
        <v-card class="text-center" flat>
            <v-card-title>
                <span>新增 商店規模條件</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheetAdd = !sheetAdd"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form1">
                <v-container class="px-6">
                    <v-row>
                        <v-col class="pr-8 pb-0">
                            <v-text-field
                                v-model="add_item.name"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                                label="輸入店家規模條件 名稱"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" width="150"
                        @click="onAddSave"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="sheetEdit" persistent>
        <v-card class="text-center" flat>
            <v-card-title>
                <span>編輯 店家規模條件</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="sheetEdit = !sheetEdit"
                > <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-form ref="form2">
                <v-container class="px-6">
                    <v-row>
                        <v-col class="pr-8 pb-0">
                            <v-text-field
                                v-model="edit_item.name"
                                :rules="$store.getters['validation/inputRules'](['required'])"
                                label="輸入店家規模條件 名稱"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn color="primary" width="150"
                        @click="onEditSave"
                    > 儲存</v-btn>
                </v-container>
            </v-form>
       </v-card>
    </v-bottom-sheet>
</v-main> -->
</v-app>
</template>
 

<script>
import OptionCRUD from '@/components/common/OptionCRUD'

export default {
    name: 'manageList',
    components:{OptionCRUD},
    props:[],
    data () {
    return {
        model:"",
        sheetAdd:false,
        sheetEdit:false,
        items: [],
        search_result:[],
        add_item:{
            scale_id:"",
            name:""
        },
        edit_item:{}
        }
    },
    computed:{
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.$store.dispatch("http/get",{
                api:"scales"
            }).then((data)=>{
                if(data.status){
                    this.items = data.result
                }
            })
        },
        
        onEditClick (item) {
            this.edit_item = item
            this.sheetEdit = true
        },
        onDeleteClick (item) {
            this.$store.commit('dialogBox',{dialog:true,option:{
                btnConfirmText:'確定刪除',
                message:'你確定要刪除'+item.name+'?',
                btnCancelShow:true,
                icon:'warning',
                btnConfirmEvent:()=>{
                    this.onDeleteSave(item.scale_id)
                }
            }});
        },
        onAddSave(){
            if (!this.$refs.form1.validate()) {
                return false
            }
            this.$store.dispatch("http/post",{
                api:"scales",
                json:this.add_item
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"新增成功！",icon:"success",color:""});
                    this.onClear()
                    this.getData()
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onEditSave(){
            if (!this.$refs.form2.validate()) {
                return false
            }
            this.$store.dispatch("http/put",{
                api:`scales/${this.edit_item.scale_id}`,
                json:this.edit_item
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"儲存成功！",icon:"success",color:""});
                    this.sheetEdit=false
                    this.getData()
                    
                }else{
                    this.$store.commit('snackBar',{show:true,message:"儲存失敗！",icon:"error",color:""});

                }
            })
        },
        onDeleteSave(id){
            this.$store.dispatch("http/delete",{
                api:`scales/${id}`
            }).then((data)=>{
                if(data.status){
                    this.$store.commit('snackBar',{show:true,message:"已刪除！",icon:"success",color:""});
                    this.getData()
                }else{
                    this.$store.commit('snackBar',{show:true,message:"刪除失敗！",icon:"error",color:""});

                }
            })
        },
        onClear(){
            this.$refs.form1.reset()
        }
   }
}
</script>
 