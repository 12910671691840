<template>
<v-container>
        <v-row>
            <v-col class="pr-8 pb-0">
                <v-text-field clearable
                    v-model="query"
                    label="輸入文字以搜尋..."
                    prepend-icon="mdi-magnify"
                    :loading="is_loading"
                ></v-text-field>
            </v-col>
            
        </v-row>
    </v-container>
</template>
 

<script>
import { debounce } from "debounce";

export default {
  props:["items","fields"],
  data () {
    return {
        is_loading:false,
        query:"",
        search_result:[]
    }
  },
    watch:{
        "items":{
            handler(newVal,oldVal){
                this.querySelections()
            }
        },
        "query":{
            handler(newVal,oldVal){
                if(newVal!=""&&newVal!=null&&newVal!=oldVal){
                    this.onDebounce()
                }else if(newVal==""||newVal==null){
                    this.search_result=this.items
                    this.$emit('emitSearch',this.search_result);

                }
            }
        },
       
    },
   
    methods:{
        onDebounce: debounce( function() {
            this.querySelections()
        }, 800),
        querySelections () {
            this.is_loading=true
            this.search_result = this.items.filter(e => {
                let flag = false
                for(let idx in this.fields){
                    let key = this.fields[idx]
                    if((e[key] || '').toLowerCase().indexOf((this.query || '').toLowerCase()) > -1) flag=true
                }
                return flag
            })
            setTimeout(()=>{
                this.is_loading=false
                this.$emit('emitSearch',this.search_result);

            },1500)
        },
        
    }
}
</script>
 